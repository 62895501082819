import React from 'react'
import { graphql } from 'gatsby'
import useGatsbyData from '../../hooks/use-gatsby-data'
import ImportUpload from 'components/import-upload'
import styled from 'styled-components'
import { theme } from '../../twconfig'
import tw from 'tailwind.macro'
import viewportMotion from '../viewport-motion'
import { motion } from 'framer-motion'
import { motionTransition } from '../../util'
import { SquaresBackgroundSvg } from '../../templates/home'
import TextTransition from '../text-transition'
import { emptyVariants } from '../../util'
import Container from '../container'
import { useMediaQueryContext } from '../../context/media'

const svgWithFill = (Component, color) => props => <Component fill={color} {...props} />

const Logo = ({modulePath, ...props}) => (
  <ImportUpload modulePath={modulePath}>
    {({ default: Component }) => {
      return <Component {...props} />
    }}
  </ImportUpload>
)

const ClientListItem = styled(motion.li)`
  svg {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
 
  @media (max-width: ${parseInt(theme.screens.md) - 1}px) {
    ${tw`w-40 h-16 mx-auto`}

    svg {
      max-height: 100%;
      width: auto;
    }
  }
  
  @media (min-width: ${theme.screens.md}) {
    height: auto;

    svg {
      height: auto;
      max-width: 100%;
    }
  }

  g,
  path {
    transition: all .4s ease-in-out;
  }
  
  .entered &:not(:hover) {
    g,
    path {
      transition: all .4s ease-in-out;
      fill: inherit;
    }
  }

  @media (min-width: ${theme.screens.lg}) {
    &.client-kti {
      svg {
        position: relative;
        left: 12.5%;
      }
    }
  }
`

const ClientLogo = svgWithFill(Logo, `#222`)
// const ClientLogo = Logo

const ClientsWrapper = styled(Container)`
  ${tw`mx-auto md:mx-10 lg:mx-auto`}
`

const listDesktopVariants = {
  visible: {
    transition: {
      staggerChildren: .25,
    }
  },
  hidden: {}
}

const visibleVariant = { opacity: 1, x: 0, y: 0 }

const listItemDesktopVariants =  {
  visible: visibleVariant,
  hidden: {
    opacity: 0,
    y: 50
  },
}

const Clients = ({ className = ``, data, ...props }) => {
  const items = data.map(({ id, logo, title, url, slug }) => {
    let LogoComponent

    if ( /\.svg$/.test(logo.relativePath) ) {
      const modulePath = logo.relativePath
      LogoComponent = ({ className, ...props }) => <ClientLogo className={className} fill={theme.colors.gray[`500`]} modulePath={modulePath} />
      
    } else {
      LogoComponent = props => <img {...props} src={logo.publicUrl} alt={title} />
    }

    const item = <LogoComponent className="max-w-full pointer-events-none" />

    return (
      <ClientListItem
        key={id}
        variants={listItemDesktopVariants}
        className={`client my-8 flex justify-center items-center md:h-auto md:w-1/2 lg:w-1/4 lg:px-10 lg:py-4 lg:flex-1 client-${slug}`}
        transition={motionTransition}
      >
        {url ? (
          <a rel="noopener noreferrer" target="_blank" href={url}>
            {item}
          </a>
        ) : (
          item
        )}
      </ClientListItem>
    )
  } )
  
  return items.length ? (
    <div className={`container ${className}`} {...props}>
      <ClientsWrapper>
        <viewportMotion.ul
          variants={listDesktopVariants}
          className="block mt-12 lg:mt-20 lg:max-w-none md:flex md:flex-row md:flex-wrap lg:flex-no-wrap lg:items-center lg:-mx-10"
        >
          {items}
        </viewportMotion.ul>
      </ClientsWrapper>
    </div>
  ) : null
}

const ClientsSection = ({ id, data, ...props  }) => (
  <section id={id} className="py-12 lg:pt-12 lg:pb-32" data-loadable-fallback={`section-${id}`}>
    <SquaresBackgroundSvg>
      <h2 className="section-title">
        <viewportMotion.span
          variants={emptyVariants}
          className="inline-block bg-black px-4"
        >
          <TextTransition text="Clients" />
        </viewportMotion.span>
      </h2>
    </SquaresBackgroundSvg>

    <Clients data={data} />
  </section>
)

const ClientsSectionWithData = ({ data: { allClient: { nodes } }, ...props }) => {
  return (
    <ClientsSection {...props} data={nodes} />
  )
}

export default ClientsSectionWithData

export const clientLogosFragment = graphql`
  fragment ClientsFragment on Query {
    allClient(
      sort: { fields: [order], order: [ASC] }
    ) {
      nodes {
        id
        title
        slug
        logo {
          publicURL
          relativePath
        }
      }
    }
  }
`
